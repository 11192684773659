import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_INVENTARIO_TI

export default class StatusService {
  // Obtener los estados
  getStatus (params) {
    return http.get(`${baseUrl}/status/listar-estados`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  // Crear un estado
  createStatus (body) {
    return http.post(`${baseUrl}/status/crear-estado`, body, {
      headers: {
        loading: true
      }
    })
  }

  // Editar un estado
  editStatus (id, body) {
    return http.put(`${baseUrl}/status/editar-estado/${id}`, body, {
      headers: {
        loading: true
      }
    })
  }

  // Eliminar un estado
  deleteStatus (id) {
    return http.delete(`${baseUrl}/status/eliminar-estado/${id}`, {
      headers: {
        loading: true
      }
    })
  }
}
