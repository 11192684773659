<script setup>
import { onMounted, ref } from 'vue'
import { useToast } from 'primevue/usetoast'
import { FilterMatchMode } from 'primevue/api'
import StatusService from '@/apps/dailyTech/inventario-ti/services/status.service'
import Icon from '@/components/Icon/index.vue'
import Swal from 'sweetalert2'

const toast = useToast()
const _StatusService = ref(new StatusService())
const modalStatus = ref(false)
const listStatus = ref([])
const statusItem = ref({})
const submitted = ref(false)
const isEdit = ref(false)
const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS }
})

// Obtener los tipos de estados
const fetchStatus = async () => {
  try {
    const { status, data } = await _StatusService.value.getStatus()
    if (status === 200) {
      listStatus.value = data
    }
  } catch (error) {
    console.error(error.message)
  }
}

// Abrir el modal
const openModalStatus = (item) => {
  modalStatus.value = true
  submitted.value = false

  if (item) {
    statusItem.value = { ...item }
    isEdit.value = true
  } else {
    statusItem.value = {
      id: null,
      statusname: '',
      description: null,
      icon: 'material-symbols:devices-off-outline-rounded',
      status: true
    }
    isEdit.value = false
  }
}

// Cerrar el modal
const hideModalStatus = () => {
  modalStatus.value = false
  submitted.value = false
}

// Crear un estado
const createStatus = async () => {
  try {
    // Validar que el estado tenga un nombre
    if (!statusItem.value.statusname) {
      toast.add({ severity: 'error', summary: 'Error', detail: 'El nombre es requerido', life: 3000 })
      return
    }
    const { status } = await _StatusService.value.createStatus(statusItem.value)
    if (status === 200) {
      toast.add({ severity: 'success', summary: 'Exito', detail: 'Estado creado exitosamente', life: 3000 })
      await fetchStatus()
      hideModalStatus()
      modalStatus.value = false
      statusItem.value = {}
    }
  } catch (error) {
    console.error(error.message)
  }
}

// Actualizar un estado
const updateStatus = async () => {
  try {
    // Validar que el estado tenga un id y nombre
    if (!statusItem.value.id || !statusItem.value.statusname) {
      toast.add({ severity: 'error', summary: 'Error', detail: 'El nombre es requerido', life: 3000 })
      return
    }

    const { status } = await _StatusService.value.editStatus(statusItem.value.id, statusItem.value)
    if (status === 200) {
      toast.add({ severity: 'success', summary: 'Exito', detail: 'Estado actualizado exitosamente', life: 3000 })
      await fetchStatus()
      hideModalStatus()
      modalStatus.value = false
      statusItem.value = {}
    }
  } catch (error) {
    console.error(error.message)
  }
}

// Guardar o actualizar un estado
const onSubmit = async () => {
  submitted.value = true
  if (isEdit.value && statusItem.value.id) {
    await updateStatus()
  } else {
    await createStatus()
  }
}

// Eliminar un estado
const deleteStatus = async (id) => {
  try {
    if (id) {
      Swal.fire({
        icon: 'warning',
        title: 'Advertencia',
        text: 'Desea eliminar el estado?, esta acción no se puede revertir.',
        showDenyButton: true,
        denyButtonText: 'No, Cancelar',
        confirmButtonText: 'Si, Eliminar'
      }).then((result) => {
        if (result.isConfirmed) {
          _StatusService.value.deleteStatus(id).then(({ status }) => {
            if (status !== 200) return

            toast.add({ severity: 'success', summary: 'Exito', detail: 'Estado eliminado exitosamente', life: 3000 })
            fetchStatus()
            hideModalStatus()
            modalStatus.value = false
            statusItem.value = {}
          }).catch((err) => {
            console.error(err.message)
            if (err.response.status) {
              toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'No se ha podido eliminar el estado',
                life: 3000
              })
            }
          })
        }
      })
    } else {
      toast.add({ severity: 'error', summary: 'Error', detail: 'No se ha podido eliminar el estado', life: 3000 })
    }
  } catch (error) {
    console.error(error.message)
  }
}

// Hook onMounted
onMounted(async () => {
  await fetchStatus()
})
</script>

<template>
  <Toast/>
  <Toolbar class="box mt-4 py-2 px-4">
    <template #start>
      <Button
        label="Regresar"
        icon="pi pi-arrow-left"
        class="p-button-sm"
        @click="$router.push({ name: 'daily-tech.inventario-ti.configuracion'})"/>
    </template>
    <template #end>
      <Button
        label="Agregar"
        icon="pi pi-plus"
        size="small"
        severity="success"
        @click="openModalStatus(null)"
      />
    </template>
  </Toolbar>

  <!-- Listado de los estados -->
  <div class="box mt-4 intro-x">
    <DataTable
      showGridlines
      ref="dt"
      :value="listStatus"
      class="p-datatable-sm p-4 text-sm"
      dataKey="id"
      :paginator="true"
      :rows="10"
      :filters="filters"
      sortField="id"
      :sortOrder="-1"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[5,10,25,50]"
      currentPageReportTemplate="Mostrando registros del {first} al {last} de un total de {totalRecords} registros"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
          <h3 class="p-mb-2 p-m-md-0 p-as-md-center text-lg">Listado de estados</h3>
          <span class="p-input-icon-left">
            <i class="pi pi-search"/>
            <InputText
              v-model="filters['global'].value"
              placeholder="Buscar..."/>
          </span>
        </div>
      </template>

      <!-- Columnas -->
      <template #empty>
        <div
          class="flex flex-col items-center justify-center"
          style="height: 500px">
          <Icon
            icon="flat-color-icons:services"
            class="text-gray-200"
            style="font-size: 15rem; opacity: 0.3"/>
          <span class="text-gray-400 text-2xl">No se encontraron registros</span>
          <span class="text-gray-300">No hemos encontrado ningún elemento, pruebe con otro filtro u opción</span>
        </div>
      </template>
      <template #loading> Cargando la información, por favor espere...</template>
      <Column
        field="id"
        header="Id"
        :sortable="true"
      />
      <Column
        field="statusname"
        header="Nombre"
        :sortable="true"
      />
      <Column
        field="description"
        header="Descripción"
      />
      <Column
        field="icon"
        header="Icono">
        <template #body="{ data }">
          <Icon
            :icon="data.icon"
            style="font-size: 1.5rem;"/>
        </template>
      </Column>
      <Column
        field="status"
        header="Estado"
        :sortable="true">
        <template #body="{ data }">
          <span :class="'bank-badge px-2 py-1 text-xs font-bold leading-none text-white rounded ' + (data.status === true ? 'bg-green-700' : 'bg-red-700')">{{ data.status === true ? 'Activo' : 'Inactivo' }}</span>
        </template>
      </Column>
      <Column
        :exportable="false"
        header="Acciones"
        style="text-align: center">
        <template #body="{ data }">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-warning p-mr-2"
            @click="openModalStatus(data)"/>
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-danger"
            @click="deleteStatus(data.id)"/>
        </template>
      </Column>
    </DataTable>
  </div>

  <!-- Modal formulario estados -->
  <Dialog
    v-model:visible="modalStatus"
    :style="{width: '450px'}"
    :modal="true"
    class="p-fluid"
    :pt="{
      header: { style: 'padding: 1rem;' }
    }"
  >
    <template #header>
      <h3
        :class="{'text-yellow-700': isEdit, 'text-green-700': !isEdit}"
        class="font-bold text-xl">{{ isEdit ? 'Editar' : 'Crear' }} estado</h3>
    </template>
    <div class="p-field">
      <label for="name">Nombre</label>
      <InputText
        id="name"
        v-model="statusItem.statusname"
        required="true"
        autofocus
        :class="{'p-invalid': submitted && !statusItem.statusname}"
        class="p-fluid"
      />
      <small
        class="p-error"
        v-if="submitted && !statusItem.statusname">El nombre es requerido.</small>
    </div>
    <div class="p-field">
      <label for="name">Descripción:</label>
      <Textarea
        id="description"
        v-model="statusItem.description"
        class="p-fluid"
        rows="3"
      />
    </div>
    <!-- Icono -->
    <div class="p-field">
      <label for="icon">Icono</label>
      <div class="flex items-center gap-2">
        <InputText
          id="icon"
          v-model="statusItem.icon"
          class="p-fluid"
          autocomplete="off"
        />
        <!-- Visualizar icon en tiempo real -->
        <div
          class="bg-gray-200 p-2 rounded flex justify-center items-center"
          style="width: 38px; height: 38px">
          <Icon
            :icon="statusItem.icon"
            style="font-size: 2rem;"/>
        </div>
      </div>
      <span class="text-xs font-semibold">Buscar mas iconos en <a
        href="https://icon-sets.iconify.design/"
        target="_blank"
        class="text-blue-500">Iconify</a></span>
    </div>
    <div class="p-field">
      <label for="status">Estado</label>
      <InputSwitch
        v-model="statusItem.status"
        id="status"
        class="p-d-block"/>
    </div>

    <template
      #footer
      class="p-text-bold">
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-danger p-button-sm"
        @click="hideModalStatus"/>
      <Button
        label="Guardar"
        icon="pi pi-check"
        class="p-button-success p-button-sm"
        @click="onSubmit"/>
    </template>
  </Dialog>
</template>

<style scoped>

</style>
